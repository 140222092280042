@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

$off-white: #eee;

$aside: #243aa8;
$card-header: hsl(230, 65%, 27%);
$success_bg: hsl(120, 100%, 11%);
$success: hsl(150, 100%, 46%);
$failure_bg: hsl(0, 100%, 20%);
$failure: hsl(30, 100%, 80%);

*, *::before, *::after {
  box-sizing: border-box;
}

a[href^="mailto:"]::after {
  content: " \01f4e8";
}

body {
  background: $off-white;
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.is-centred {
  text-align: center;
}

.details {
  white-space: pre-line;
}

.App {
  display: grid;
  grid-template-columns: 8rem 1fr;
  grid-template-rows: 50px 1fr;
  height: 100vh;
}

header {
  background: white;
  display: flex;
  grid-column: span 2;
  justify-content: center;
  align-items: center;
}

aside {
  background: $aside;
  color: $off-white;
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
  padding-top: 1rem;

  nav {
    position: sticky;
    top: 1rem;
  }

  a {
    color: $off-white;
    display: block;
    padding: 0.4rem;
    text-decoration: none;

    &:hover {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, .7);
    }
  }

  .active {
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

.card {
  border: 1px solid darken($card-header, 10%);
  display: flex;
  flex-direction: column;

  &__header {
    background: $card-header;
    color: $off-white;
    font-family: 'Nunito', Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0.25rem 0.5rem;

    small {
      font-size: 1.1rem;
    }
  }

  &__content {
    padding: 0 0.5rem;
  }
}

.launches, .missions, .rockets {
  padding: 1rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(50ch, 1fr));

  h1, h2 {
    grid-column: 1 / -1;
  }

  .success {
    background: $success_bg;
    color: $success;
  }

  .failure {
    background: $failure_bg;
    color: $failure;
  }
}

.holding {
  padding: 1rem;

  p {
    font-size: 1.2rem;
  }

  img {
    margin: 0 auto;
    width: 70vw;
  }
}

section.raw-data {
  border: 1px solid darken($off-white, 30);
  background: 1px solid darken($off-white, 10);
  overflow-x: scroll;
  padding: 0.5rem;
  width: 80vw;
  height: 60vh;

  h2 {
    font-size: 1.5rem;
  }

  pre {
    font-size: 0.9rem;
    word-break: break-word;
  }
}

.loading {
  font-size: 2rem;
  text-align: center;
}
